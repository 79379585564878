import React, { FunctionComponent, ReactElement } from 'react'
import { Markup } from 'interweave'
import { useTranslation } from 'react-i18next'
import Modal, { ModalClosingButton } from '../modal'
import GeneralInformation from '../../entities/GeneralInformation'
import style from './generalInformation.css'

export type Props = {
    isVisibleState: [boolean, Function]
    information: GeneralInformation[]
    children?: React.ReactNode
}

const GeneralInformationModal: FunctionComponent<Props> = ({information, isVisibleState, children}) => {

    const [modalIsActive, setModalIsActive] = isVisibleState
    const { t } = useTranslation()

    if (!modalIsActive || information === undefined || information.length === 0) {
        return null
    }

    const closeModal = (): void => {
        setModalIsActive(false)
    }

    const informationContent: ReactElement[] = information.map((info) => (
        <p key={info.key}>
            <Markup content={t(`generalInformation.${info.key}.text`, info.payload)} />
        </p>
    ))

    return (
        <Modal onClose={closeModal} containerClassName={style.modalContainer}>
            <ModalClosingButton onClick={closeModal} />
            <article className={style.container}>
                <h2 className={style.title}>{t('generalInformation.title')}</h2>
                {informationContent}
                {children}
            </article>
        </Modal>
    )
}

export default GeneralInformationModal
