import { connect, MapStateToProps } from 'react-redux'
import { Props } from './FastConfigurationButton'
import fastConfigurationButtonDisabledSelector from '../../redux/selectors/feature-scope/fastConfigurationButtonDisabledSelector'

type StateProps = Pick<Props, 'isDisabled'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    isDisabled: fastConfigurationButtonDisabledSelector(state),
})

export default connect(mapStateToProps)
