import styled, { css } from 'styled-components'
import { ButtonVariant } from './Button'

export const IconWrapper = styled.span`
    margin-right: var(--space-s);
    align-items: center;
    height: 100%;
`

export const ButtonContainer = styled.button<{ $variant: ButtonVariant }>`
    ${(props) => {
        switch (props.$variant) {
            case 'primary':
                return css`
                    background-color: var(--color-black);
                    color: var(--color-white);
                `
            case 'secondary':
                return css`
                    background-color: var(--color-grey-10);
                    color: var(--color-black);
                `
        }
    }};
    padding: var(--space-m);
    border-width: 0;
    min-width: 10em;
    display: inline-flex;
    align-items: center;
    justify-content: center;
`

export const Label = styled.span`
    text-align: center;
`
