import { connect, MapStateToProps } from 'react-redux'
import { Props } from './EnterVehicleCodeButton'
import brandSelector from '../../redux/selectors/brandSelector'
import loadAudiCodeButtonDisabledSelector from '../../redux/selectors/feature-scope/loadAudiCodeButtonDisabledSelector'

type StateProps = Pick<Props, 'brand' | 'isDisabled'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state) => ({
    brand: brandSelector(state),
    isDisabled: loadAudiCodeButtonDisabledSelector(state),
})

export default connect(mapStateToProps)
