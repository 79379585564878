import { createReducer } from 'typesafe-actions'
import ConfigurationResponse from '../../entities/ConfigurationResponse'
import {
    resetConfigurationDataState,
    setConfigurationDataState,
    setConfigurationRescueConflictAccepted,
} from '../actions/app/configuration.actions'

export type ConfigurationDataState = ConfigurationResponse & { rescueConflictAccepted?: boolean }

export const defaultState: ConfigurationDataState = {
    configurationString: '',
    carlineId: '',
    modelId: '',
    orderKey: null,
    configurationState: 'INCOMPLETE',
    conflictInformation: {
        conflictSteps: [],
        hiddenFields: [],
        resolvable: false,
        total: '',
        trigger: null,
    },
}

const configurationDataReducer = createReducer(defaultState)
    .handleAction(setConfigurationDataState, (state, action) => action.payload)
    .handleAction(setConfigurationRescueConflictAccepted, (state, action) => ({
        ...state,
        rescueConflictAccepted: action.payload,
    }))
    .handleAction(resetConfigurationDataState, () => defaultState)

export default configurationDataReducer
